@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("CalibreBold"),
    url("./fonts/CalibreBold.otf") format("opentype");
}

@font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("CalibreMedium"),
    url("./fonts/CalibreMedium.otf") format("opentype");
}

@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("CalibreRegular"),
    url("./fonts/CalibreRegular.otf") format("opentype");
}

@font-face {
  font-family: "Semibold";   /*Can be any text*/
  src: local("CalibreSemibold"),
    url("./fonts/CalibreSemibold.otf") format("opentype");
}

@font-face {
  font-family: "SemiboldFixed";   /*Can be any text*/
  src: local("CalibreSemiboldNameScreen"),
    url("./fonts/CalibreSemiboldNameScreen.otf") format("opentype");
}

@font-face {
  font-family: "BoldFixed";   /*Can be any text*/
  src: local("CalibreBoldNameScreen"),
    url("./fonts/CalibreBoldNameScreen.otf") format("opentype");
}
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.flip-clock {
  --fcc-flip-duration: 0.5s; /* transition duration when flip card */
  --fcc-digit-block-width: 30px; /* width of digit card */
  --fcc-digit-block-height: 60px; /* height of digit card, highly recommend in even number */
  --fcc-digit-font-size: 30px; /* font size of digit */
  --fcc-digit-color: #000000; /* color of digit */
  --fcc-label-font-size: 14px; /* font size of label */
  --fcc-label-color: #fff; /* color of label */
  --fcc-background: #ffffff; /* background of digit card */
  --fcc-divider-color: #000000; /* color of divider */
  --fcc-divider-height: 1px; /* height of divider */
  --fcc-separator-size: 6px; /* size of colon */
  --fcc-separator-color: #fff; /* color of colon */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  color: white;
  background-color: #181818;
}

.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.page__content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  font-family: 'Semibold';
}

.page h2 {
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 1rem;
  font-family: 'Semibold';
}

.page h3 {
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  max-width: 460px;
  margin-bottom: 3rem;
  font-family: 'Semibold';
  margin-left: 20px;
  margin-right: 20px;
}

.page video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.flip-clock {
  font-family: 'SemiboldFixed';
  font-weight: 700;
  margin-bottom: 2rem;
}

.btn {
  width: 180px;
  height: 64px;
  background: #fff;
  color: #000;
  font-size: 1rem;
  font-family: 'BoldFixed';
  letter-spacing: 1px;
  border-radius: 5.4rem;
  font-weight: 700;
  cursor: pointer;
}

.hero-container-2 {
  position:static;
  align-items: start;
  justify-content: start;
  /* top: -50px; */
  margin-bottom: -60px;
  margin-top: -300px;
}

/* screens bigger than 900px */
@media (min-width: 900px) {
  .page h1 {
    font-size: 4rem;
  }
  .page h3 {
    max-width: 800px;
  }
  .flip-clock {
    --fcc-digit-block-width: 80px;
    --fcc-digit-block-height: 120px;
    --fcc-digit-font-size: 60px;
    --fcc-label-font-size: 20px;
  }
  .hero-container-2 {
    position:static;
    align-items: start;
    justify-content: start;
    top: 0px;
  }
}
